{
  "content": [
    {
      "url": "images/NICK9625_DxO.jpg",
      "id": "downy-woodpecker",
      "type": "image"
    },
    {
      "url": "images/NICK2205_DxO.jpg",
      "id": "golden-eagle",
      "type": "image"
    },
    {
      "url": "images/nickfunk-sheep-river-35p.jpg",
      "id": "sheep-river",
      "type": "image"
    },
    {
      "url": "images/NICK9457_DxO.jpg",
      "id": "lake-star",
      "type": "image"
    },
    {
      "url": "images/NICK8655_DxO_1.jpg",
      "id": "american-tree-sparrow",
      "type": "image"
    },
    {
      "url": "images/NICK1341_DxO_1.jpg",
      "id": "bald-eagle",
      "type": "image"
    },
    {
      "url": "images/NICK7191_DxO.jpg",
      "id": "columbia-wetlands",
      "type": "image"
    },
    {
      "url": "images/NICK8033_DxO.jpg",
      "id": "wax-wings-launch",
      "type": "image"
    },
    {
      "url": "images/NICK8401_DxO.jpg",
      "id": "road-sign-magpie",
      "type": "image"
    },
    {
      "url": "images/NICK9776_DxO_1.jpg",
      "id": "carburn-evening-water",
      "type": "image"
    },
    {
      "url": "images/NICK8377_DxO.jpg",
      "id": "hunter-gsp-field",
      "type": "image"
    },
    {
      "url": "images/NICK0837_DxO.jpg",
      "id": "blue-heron",
      "type": "image"
    },
    {
      "url": "images/NICK2392_DxO.jpg",
      "id": "sleepy-great-horn",
      "type": "image"
    },
    {
      "url": "images/NICK1550_DxO_1.jpg",
      "id": "grumpy-pelicans",
      "type": "image"
    },
    {
      "url": "images/NICK2600_DxO.jpg",
      "id": "snowy-owl-portrait",
      "type": "image"
    },
    {
      "url": "images/P1320117_DxO.jpg",
      "id": "train-tracks",
      "type": "image"
    },
    {
      "url": "images/NICK2371_DxO_1.jpg",
      "id": "coopers-hawk",
      "type": "image"
    },
    {
      "url": "images/NICK9330_DxO.jpg",
      "id": "ducks-bow-river",
      "type": "image"
    },
    {
      "url": "images/NICK9849_DxO.jpg",
      "id": "ducks-carburn",
      "type": "image"
    },
    {
      "url": "https://www.youtube.com/watch?v=ZrEaoI0VO68",
      "id": "yt-cold-river-adventures",
      "type": "youtube"
    }
  ],
  "layouts": [
    {
      "name": "three",
      "columns": [
        {
          "index": 0,
          "imageIDs": [
            "downy-woodpecker",
            "yt-cold-river-adventures",
            "sheep-river",
            "columbia-wetlands",
            "carburn-evening-water",
            "sleepy-great-horn",
            "train-tracks",
            "ducks-carburn"
          ]
        },
        {
          "index": 1,
          "imageIDs": [
            "golden-eagle",
            "american-tree-sparrow",
            "wax-wings-launch",
            "hunter-gsp-field",
            "grumpy-pelicans",
            "coopers-hawk"
          ]
        },
        {
          "index": 2,
          "imageIDs": [
            "lake-star",
            "bald-eagle",
            "road-sign-magpie",
            "blue-heron",
            "snowy-owl-portrait",
            "ducks-bow-river"
          ]
        }
      ]
    },
    {
      "name": "two",
      "columns": [
        {
          "index": 0,
          "imageIDs": [
            "downy-woodpecker",
            "yt-cold-river-adventures",
            "lake-star",
            "columbia-wetlands",
            "carburn-evening-water",
            "sleepy-great-horn",
            "train-tracks",
            "ducks-carburn",
            "sheep-river",
            "road-sign-magpie",
            "ducks-bow-river"
          ]
        },
        {
          "index": 1,
          "imageIDs": [
            "golden-eagle",
            "american-tree-sparrow",
            "wax-wings-launch",
            "hunter-gsp-field",
            "grumpy-pelicans",
            "coopers-hawk",
            "bald-eagle",
            "blue-heron",
            "snowy-owl-portrait"
          ]
        }
      ]
    }
  ]
}