import "./gallery.css";

import { GalleryImage } from "./image";
import { LoadCoordinator } from "./loadCoordinator";
import { GalleryModal } from "./modal";
import { YouTubeVideo } from "./youtubeVideo";

export enum ContentDateType {
  Image = "image",
  YouTube = "youtube",
}

export interface ContentDataModel {
  url: string;
  id: string;
  type: string;
}

export interface ColumnModel {
  index: number;
  imageIDs: string[];
}

export interface LayoutModel {
  name: string;
  columns: ColumnModel[];
}

export interface GalleryDataModel {
  content: ContentDataModel[];
  layouts: LayoutModel[];
}

export class Gallery {
  private id: string;
  private root: HTMLDivElement;

  private items: (GalleryImage | YouTubeVideo)[];

  private modal: GalleryModal;
  private onShowImageDelegate: (
    type: ContentDateType,
    url: string,
    width: number,
    height: number
  ) => void;

  private coordinator: LoadCoordinator;

  constructor(id: string, data: GalleryDataModel, layoutName: string) {
    this.id = id;
    this.coordinator = new LoadCoordinator();

    const el = document.getElementById(this.id) as HTMLDivElement;
    if (!el) {
      throw new Error("unable to find gallery element");
    }

    this.root = el;

    this.items = [];

    // make modal
    this.modal = new GalleryModal();
    this.root.appendChild(this.modal.root);

    this.root.classList.add("gallery-root");

    this.onShowImageDelegate = this.onShowImage.bind(this);

    // make columns

    const layout = data.layouts.find((l) => l.name === layoutName);
    if (!layout) {
      console.error("unable to find layout in data");
      return;
    }

    const columns: HTMLElement[] = [];
    for (let i = 0; i < layout.columns.length; i++) {
      const column = document.createElement("column");
      column.classList.add("gallery-column");
      columns.push(column);

      this.root.appendChild(column);
    }

    for (let c = 0; c < layout.columns.length; c++) {
      const col = layout.columns[c];
      const column = columns[col.index];
      let index = 0;

      for (const imageID of col.imageIDs) {
        const content = data.content.find((i) => i.id === imageID);
        if (!content) {
          console.warn(`unable to find image by id ${imageID}`);
          continue;
        }

        if (content.type === ContentDateType.Image) {
          const image = new GalleryImage(
            this.onShowImageDelegate,
            c,
            index,
            content.url,
            this.coordinator,
          );

          this.items.push(image);
          column.appendChild(image.root);
          index++;
        } else if (content.type === ContentDateType.YouTube) {
          const video = new YouTubeVideo(
            this.onShowImageDelegate,
            c,
            index,
            content.url,
            this.coordinator
          );

          this.items.push(video);
          column.appendChild(video.root);
          index++;
        }
      }
    }
  }

  public onShowImage(
    type: ContentDateType,
    url: string,
    width: number,
    height: number
  ) {
    this.modal.show(type, url, width, height);
  }
}
