import "./image.css";
import "./animate.css";

import { ContentDateType } from "./gallery";
import { LoadCoordinator } from "./loadCoordinator";

export class GalleryImage {
  public readonly root: HTMLElement;
  public readonly fullUrl: string;
  public readonly previewUrl: string;

  private onShowImage: (
    type: ContentDateType,
    url: string,
    width: number,
    height: number
  ) => void;
  private column: number;
  private index: number;

  private onClickDelegate: (ev: Event) => void;

  private previewImage: HTMLImageElement;
  private onLoadDelegate: () => void;
  private tryLoadDelegate: () => void;
  private setLoadedDelegate: () => void;
  private coordinator: LoadCoordinator;

  constructor(
    onShowImage: (
      type: ContentDateType,
      url: string,
      width: number,
      height: number
    ) => void,
    column: number,
    index: number,
    url: string,
    coordinator: LoadCoordinator
  ) {
    this.onShowImage = onShowImage;
    this.column = column;
    this.index = index;
    this.coordinator = coordinator;

    this.onLoadDelegate = this.onLoad.bind(this);
    this.tryLoadDelegate = this.tryLoad.bind(this);
    this.setLoadedDelegate = this.setLoaded.bind(this);

    const ext = this.getExt(url);
    const urlWithNoExtension = url.replace(ext, "");

    this.previewUrl = `${urlWithNoExtension}-med${ext}`;
    this.fullUrl = `${urlWithNoExtension}-large${ext}`;

    this.root = document.createElement("div");
    this.root.classList.add("gallery-item");
    this.root.classList.add(`item-${this.index}`);

    this.previewImage = document.createElement("img");
    this.previewImage.classList.add("gallery-image");
    this.previewImage.src = this.previewUrl;
    this.previewImage.onload = this.onLoadDelegate;

    this.onClickDelegate = this.onClick.bind(this);
    this.root.addEventListener("click", this.onClickDelegate);
  }

  private onClick() {
    const rect = this.root.getBoundingClientRect();

    this.onShowImage(
      ContentDateType.Image,
      this.fullUrl,
      rect.width,
      rect.height
    );
  }

  private tryLoad() {
    if (this.coordinator.canLoad(this.column, this.index)) {
      this.root.appendChild(this.previewImage);
      this.root.classList.add("gallery-item-animate-pop");

      setTimeout(this.setLoadedDelegate, 25);
    } else {
      setTimeout(this.tryLoadDelegate, 50);
    }
  }

  private setLoaded() {
    this.coordinator.loaded(this.column, this.index);
  }

  private onLoad() {
    this.tryLoad();
  }

  private getExt(url: string) {
    const period = url.lastIndexOf(".");
    return url.substring(period, url.length);
  }
}
