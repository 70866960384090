import "./youtubeVideo.css";
import "./animate.css";

import { ContentDateType } from "./gallery";
import { LoadCoordinator } from "./loadCoordinator";

export class YouTubeVideo {
  public readonly root: HTMLDivElement;

  private onShowImage: (
    type: ContentDateType,
    url: string,
    width: number,
    height: number
  ) => void;
  private column: number;
  private index: number;
  private url: string;

  private onClickDelegate: (ev: Event) => void;

  private onLoadDelegate: () => void;
  private tryLoadDelegate: () => void;
  private setLoadedDelegate: () => void;
  private previewImage: HTMLImageElement;
  private coordinator: LoadCoordinator;

  constructor(
    onShowImage: (
      type: ContentDateType,
      url: string,
      width: number,
      height: number
    ) => void,
    column: number,
    index: number,
    url: string,
    coordinator: LoadCoordinator
  ) {
    this.onShowImage = onShowImage;
    this.column = column;
    this.index = index;
    this.url = url;
    this.coordinator = coordinator;

    this.onLoadDelegate = this.onLoad.bind(this);
    this.tryLoadDelegate = this.tryLoad.bind(this);
    this.setLoadedDelegate = this.setLoaded.bind(this);

    this.root = document.createElement("div");
    this.root.classList.add("gallery-item-yt");
    this.root.classList.add(`item-${this.index}`);

    const u = new URL(this.url);
    const videoID = u.searchParams.get("v");
    const frameUrl = `https://img.youtube.com/vi/${videoID}/sddefault.jpg`;

    this.previewImage = document.createElement("img");
    this.previewImage.classList.add("gallery-yt");
    this.previewImage.src = frameUrl;
    this.previewImage.onload = this.onLoadDelegate;

    this.onClickDelegate = this.onClick.bind(this);
    this.root.addEventListener("click", this.onClickDelegate);
  }

  private tryLoad() {
    if (this.coordinator.canLoad(this.column, this.index)) {
      this.root.appendChild(this.previewImage);

      const playIcon = document.createElement("img");
      playIcon.classList.add("gallery-yt-icon");
      playIcon.src = "static/icons/playButtonIcon.svg";
      this.root.appendChild(playIcon);
  
      this.root.classList.add("gallery-item-animate-pop");

      setTimeout(this.setLoadedDelegate, 25);
    } else {
      setTimeout(this.tryLoadDelegate, 50);
    }
  }

  private setLoaded() {
    this.coordinator.loaded(this.column, this.index);
  }

  private onLoad() {
    this.tryLoad();
  }

  private onClick() {
    const rect = this.root.getBoundingClientRect();
    this.onShowImage(
      ContentDateType.YouTube,
      this.url,
      rect.width,
      rect.height
    );
  }
}
