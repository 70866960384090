import { Gallery } from "./components/Gallery/gallery";

import indexData from "./data/index.gallery.json";

const ready = async () => {
  const isSmall = window.innerWidth <= 500;
  console.log(isSmall);
  new Gallery("gallery", indexData, isSmall ? "two" : "three");
};

document.addEventListener("DOMContentLoaded", ready);
