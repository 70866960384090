import "./modal.css";

import { ContentDateType } from "./gallery";

export class GalleryModal {
  public readonly root: HTMLDivElement;
  private onCloseDelegate: (ev: Event) => void;
  private onKeyPressDelegate: (ev: KeyboardEvent) => void;
  private onLoadDelegate: () => void;

  private window: HTMLDivElement;
  private onClickWindowDelegate: (ev: Event) => void;
  private content: HTMLDivElement;

  private image: HTMLImageElement;
  private loadingPlaceholder: HTMLDivElement;

  constructor() {
    this.onCloseDelegate = this.onClose.bind(this);
    this.onKeyPressDelegate = this.onKeyPress.bind(this);
    this.onLoadDelegate = this.onImageLoad.bind(this);

    const { root } = this.createRoot();
    this.root = root;

    const { content, window } = this.createWindow();
    this.window = window;
    this.content = content;

    this.root.appendChild(this.window);

    this.loadingPlaceholder = this.createLoadingPlaceholder();

    document.addEventListener("keydown", this.onKeyPressDelegate);
  }

  private createRoot() {
    const root = document.createElement("div");
    root.classList.add("gallery-modal", "hidden");

    root.onclick = this.onCloseDelegate;

    return { root };
  }

  private createWindow() {
    const window = document.createElement("div");
    window.classList.add("gallery-modal-window");
    this.onClickWindowDelegate = this.onClickWindow.bind(this);
    window.onclick = this.onClickWindowDelegate;

    const content = document.createElement("div");
    content.classList.add("gallery-modal-content");
    window.appendChild(content);

    return { window, content };
  }

  private createLoadingPlaceholder() {
    const loading = document.createElement("div");
    loading.classList.add("gallery-modal-loading");

    loading.innerText = "Loading...";

    return loading;
  }

  private onKeyPress(ev: KeyboardEvent) {
    if (ev.key.toLowerCase() === "escape") {
      this.onClose();
    }
  }

  private onImageLoad() {
    this.content.innerHTML = "";

    if (!this.image) {
      return;
    }

    this.content.appendChild(this.image);
  }

  public onClickWindow(ev: Event) {
    ev.stopPropagation();
  }

  public onClose() {
    this.hide();
  }

  public show(
    type: ContentDateType,
    url: string,
    width: number,
    height: number
  ) {
    this.root.classList.remove("hidden");
    this.root.classList.add("visible");

    this.content.innerHTML = "";

    if (type === ContentDateType.Image) {
      this.showImage(url);
    } else if (type === ContentDateType.YouTube) {
      this.showYouTubeVideo(url, width, height);
    }
  }

  private showImage(url: string) {
    const image = document.createElement("img");
    image.classList.add("gallery-modal-image");
    image.src = url;
    image.onload = this.onLoadDelegate;

    this.image = image;

    this.content.appendChild(this.loadingPlaceholder);
  }

  private computeVideoDimensions(width: number, height: number) {
    const contentIsVertical = height > width;

    const mobileVertical =
      window.innerHeight > window.innerWidth && window.innerWidth < 650;

    let w = 0;
    let h = 0;

    if (contentIsVertical) {
      h = window.innerHeight * 0.65;
      w = (h * width) / height;
    } else {
      const ratio = mobileVertical ? 0.95 : 0.65;
      w = window.innerWidth * ratio;
      h = (w * height) / width;
    }

    return { width: w, height: h };
  }

  private showYouTubeVideo(url: string, width: number, height: number) {
    const video = document.createElement("div");
    video.classList.add("gallery-modal-video");

    const u = new URL(url);
    const videoID = u.searchParams.get("v");

    const vertical = height > width;

    const { width: w, height: h } = this.computeVideoDimensions(width, height);

    video.innerHTML = `
      <iframe width="${w}px" height="${h}px" src="https://www.youtube.com/embed/${videoID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    `;

    this.content.appendChild(video);
  }

  public hide() {
    this.root.classList.remove("visible");
    this.root.classList.add("hidden");

    this.content.innerHTML = "";
  }
}
