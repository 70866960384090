export class LoadCoordinator {
  private items: Map<string, boolean>;

  constructor() {
    this.items = new Map<string, boolean>();
    this.items.set("0:-1", true);
    this.items.set("1:-1", true);
    this.items.set("2:-1", true);
    this.items.set("3:-1", true);
  }

  public loaded(col: number, index: number) {
    this.items.set(`${col}:${index}`, true);
  }

  public canLoad(col: number, index: number) {
    const val = this.items.get(`${col}:${index - 1}`);
    return val ?? false;
  }
}